import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ListFiles = () => {
  const [fileNames, setFileNames] = useState([]);

  useEffect(() => {
    axios.get('/list-files')
      .then(response => {
        setFileNames(response.data);
      })
      .catch(error => {
        console.log('Error listing files:', error);
      });
  }, []);

  return (
    <div>
      <h1>Your Files:</h1>
      <ul>
        {fileNames.map(fileName => (
          <li key={fileName}>{fileName}</li>
        ))}
      </ul>
    </div>
  );
};

export default ListFiles;

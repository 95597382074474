import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';

const CodeGenerator = () => {
  const generateCode = () => {
    // Get the workspace
    const workspace = Blockly.getMainWorkspace();

    const jsCode = javascriptGenerator.workspaceToCode(workspace);
    // const pythonCode = pythonGenerator.workspaceToCode(workspace);
    // const phpCode = phpGenerator.workspaceToCode(workspace);
    // const luaCode = luaGenerator.workspaceToCode(workspace);
    // const dartCode = dartGenerator.workspaceToCode(workspace);

    // Display the generated code
    console.log(jsCode);
  };

  
};

export default CodeGenerator;

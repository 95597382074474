  import {useState,useEffect} from 'react';
  import './styles/background.css';
  import Navbar from './components/navbar';
  import { toAbsoluteUrl } from '../../../_metronic/helpers';


  export default function Index() {
    const [typedText, setTypedText] = useState('');
    const textArray = [
      'Incubation Cloud',
      'OS',
      'AI',
      'Metaverse',
      'Founder',
      'Investor',
      'Incubator'
    ];
  
    let textArrayIndex = 0;
    let charIndex = 0;
  
    function typeNextWord() {
      if (textArrayIndex < textArray.length) {
        if (charIndex <= textArray[textArrayIndex].length) {
          setTypedText(textArray[textArrayIndex].substring(0, charIndex));
          charIndex++;
          setTimeout(typeNextWord, 100);
        } else {
          setTimeout(erase, 1500);
        }
      }
    }
  
    function eraseCurrentWord() {
      if (charIndex >= 0) {
        setTypedText(textArray[textArrayIndex].substring(0, charIndex));
        charIndex--;
        setTimeout(eraseCurrentWord, 50);
      } else {
        textArrayIndex = (textArrayIndex + 1) % textArray.length;
        setTimeout(type, 500);
      }
    }
  
    function erase() {
      eraseCurrentWord();
    }
  
    function type() {
      typeNextWord();
    }
  
    useEffect(() => {
      type();
    }, []);
  
    return (<>
        <div className='home-page'>
        <div className='hero'>
        <a href="/">
        <img
          src={toAbsoluteUrl("/media/logos/StartupEd-Logo.png")}
          alt="Logo"
          className="home-page-logo-image"
        />
        </a>
          <div className="centered-content">
          <div className='typed-text'>
            <h1 className='home-page-Heading'>StartupEd | {typedText}</h1>
            </div>

            <h2 className='home-page-subheading'>Accelerate your startup ecosystem journey with our network copilot platform.</h2>
            <button className="fancy-button " onClick={() => window.location.href = '/talk'}>
              Get started !
            </button>
          </div>
          
        </div>
        </div>
       <div className='home-footer'>
       <div class=" bg-gray-900 ">
  <div class="mx-auto text-center flex flex-col items-center border-t pt-6 bg-gray-900">
    <nav class="mx-9  mb-4 flex flex-wrap justify-center gap-x-4 gap-y-2 md:justify-center md:gap ">
      <a href="https://www.startuped.net/privacy" target="_blank" rel="noreferrer" class="text-gray-500 mx-6 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Privacy</a>
      <a href="https://www.startuped.net/terms-and-use" target="_blank" rel="noreferrer" class="text-gray-500 mx-6 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Terms</a>
      <a href="https://www.startuped.net/faq" target="_blank" rel="noreferrer" class="text-gray-500 mx-6 transition duration-100 hover:text-indigo-500 active:text-indigo-600">FAQ</a>
      <a href="https://www.startuped.net/contact" target="_blank" rel="noreferrer" class="text-gray-500 mx-6 transition duration-100 hover:text-indigo-500 active:text-indigo-600">Contact us</a>
    </nav> 
  
        <div class="flex gap-1 ">
          <a href="https://www.instagram.com/incubation_metaverse/" target="_blank" rel="noreferrer" class="text-gray-400 mx-2 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </a>
  
          <a href="https://twitter.com/StartupEdNet" target="_blank" rel="noreferrer" class="text-gray-400 mx-2 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg>
          </a>
  
          <a href="https://www.linkedin.com/company/startuped/" target="_blank" rel="noreferrer"   class="text-gray-400 mx-2 transition duration-100 hover:text-gray-500 active:text-gray-600">
            <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </a>
          <a href="https://discord.com/invite/pGJB5NCY9T" target="_blank" rel="noreferrer"   class="text-gray-400 mx-2 transition duration-100 hover:text-gray-500 active:text-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="24" fill="currentColor" class="h-5 w-5" viewBox="0 0 16 16">
          <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
        </svg>
          </a>
          <a href="https://web.telegram.org/a/" target="_blank" rel="noreferrer"   class="text-gray-400 mx-2 transition duration-100 hover:text-gray-500 active:text-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="h-5 w-5" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>

        </svg>
          </a>
  
        </div>
      </div>
  
      <div class="py-8 text-center text-sm text-gray-400">© 2023 - StartupEd. All rights reserved.</div>
    </div>
    </div>
    </>
  );
}

import React, { FC} from 'react';
// import {useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
// import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { PageTitle } from '../../../_metronic/layout/core';
import FileList from './FileList';

// import {
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
//   CardsWidget7,
//   CardsWidget17,
//   CardsWidget20,
//   ListsWidget26,
//   EngageWidget10,
// } from '../../../_metronic/partials/widgets';

const DashboardPage: FC = () => {
  const [message, setMessage] = React.useState(null);

 

React.useEffect(() => {
  const fetchMessage = async () => {
    try {
      const response = await fetch('/api');
      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      console.error(error);
    }
  };

  fetchMessage();
}, []);

  return (
    <>
    <div className="card card-custom ">
      <div className="card-header ">
        <h3 className="card-title">{message}</h3>
      </div>

    </div>
    <FileList/>

    </>
  );  
};

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { requestPassword } from '../core/_requests';
import * as Yup from 'yup';

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);

      try {
        const { result } = await requestPassword(values.email);
        setHasErrors(!result);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus('The email entered is incorrect');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='form-group'>
        <label>Email</label>
        <input
          type='email'
          className='form-control'
          {...formik.getFieldProps('email')}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='text-danger'>{formik.errors.email}</div>
        )}
      </div>

      {hasErrors === true && (
        <div className='alert alert-danger' role='alert'>
          Sorry, an error occurred. Please try again.
        </div>
      )}

      {hasErrors === false && (
        <div className='alert alert-success' role='alert'>
          Password reset email sent. Please check your email.
        </div>
      )}

      <button
        type='submit'
        className='btn btn-primary'
        disabled={formik.isSubmitting}
      >
        {loading ? 'Loading...' : 'Reset Password'}
      </button>

      <div className='mt-3'>
        <Link to='/auth/login'>Back to Login</Link>
      </div>
    </form>
  );
}

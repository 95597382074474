import React from 'react';

const WalletSection = ({ connectedWalletAddress }) => {
  return (
    <div className="mt-4">
      <h2>Wallet Information</h2>
      <p>Connected Wallet Address: <strong>{connectedWalletAddress}</strong></p>
      <p>Current IPT Price: <strong>$0.50</strong></p>
      <p>Minimum Investment: <strong>100 IPT</strong></p>
    </div>
  );
};

export default WalletSection;

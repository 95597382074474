  import React, { useState, useRef, useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import './styles/searchPage.css';
  import SearchWindow from './components/SearchWindow';
  import { toAbsoluteUrl } from '../../../_metronic/helpers';
  import axios from 'axios';
  import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

  // import useClipboard from "react-use-clipboard";


  // const backend_Dev = "https://startuped.dev/api/"
  // const backend_Prod = "https://startuped.app/api/"
  // const target_backend = backend_Prod;    //  Toggable Component 


  const ENTITY_DATA = [
  // { name: 'founder', label: 'Founders' },
    { name: 'InvestorContact', label: 'Investors' },
    { name: 'IncubationProgram', label: 'Incubators' },
    //{ name: 'mentor', label: 'Mentors' },
    { name: 'web3funds', label: 'Web3 Funds' },
    { name: 'grant', label: 'Grants' },
    //{ name: 'event', label: 'Events' },
    // Add more entities here as needed
  ];

  const SearchPage = () => {
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const searchBoxRef = useRef(null);
    const navigate = useNavigate();
    const [showSearchWindow, setShowSearchWindow] = useState(false);
    const [loading, setLoading] = useState(false); // State to handle loading status
    const [suggestions, setSuggestions] = useState([]); // State for dynamic suggestions from the API
    

    const toggleEntitySelection = (entity) => {
      setSelectedEntities((prevSelectedEntities) =>
        prevSelectedEntities.includes(entity)
          ? prevSelectedEntities.filter((item) => item !== entity)
          : [...prevSelectedEntities, entity]
      );
    };

    const handleInputChange = (e) => {
      setSearchValue(e.target.value);
      setShowSearchWindow(true);

      // Fetch the suggestions from the API based on the searchValue and selected entities
      if (e.target.value.trim()) {
        fetchSuggestionsFromApi(e.target.value, selectedEntities)
          .then((data) => setSuggestions(data))
          .catch((error) => console.error('Error fetching suggestions:', error));
      } else {
        setSuggestions([]); // Clear suggestions if the searchValue is empty
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    };
    const getIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org/?format=json');
        return response.data.ip;
      } catch (error) {
        console.error('Error fetching IP address:', error);
        return 'Unknown';
      }
    };
    
    const sendSlackMessage = (message, ipAddress) => {
      const serverUrl = '/api/send-slack-message'; // Replace with your server URL
    
      // Post the message and IP address to your server
      axios.post(serverUrl, { message, ipAddress })
        .then(() => {
          console.log('Message and IP address sent to server successfully.');
        })
        .catch((error) => {
          console.error('Error sending message and IP address to server:', error);
        });
    };

    const handleSearch = async (query = searchValue) => {
      // Check if the query is empty or contains only white spaces
      if (!query.trim()) {
        return;
      }
    
      setLoading(true);
      try {
        const entityTypes = selectedEntities.join(',');
    
        const ipAddress = await getIpAddress();
    
        // Send the search keyword and IP address to your server
        const message = `${query}`;
    
        // Call the Slack sending function without await
        sendSlackMessage(message, ipAddress);
    
        // Navigate to the search results page with the search query and entity types
        navigate(
          `/search-results?query=${encodeURIComponent(query)}&entity=${encodeURIComponent(entityTypes)}`
        );
      } catch (error) {
        console.error('Error fetching search results:', error);
        setLoading(false);
      }
    };
    
    const handleSearchWindowSelect = (item) => {
      setSearchValue(item);
      setShowSearchWindow(false);
      handleSearch(item);
    };
    
    // const handleSearchBoxClick = () => {
    //   setShowSearchWindow(true);
    // };

    const handleOutsideClick = (e) => {
      if (searchBoxRef.current && !searchBoxRef.current.contains(e.target)) {
        setShowSearchWindow(false);
      }
    };

    // Attach event listener for click outside the search box
    useEffect(() => {
      document.addEventListener('mousedown', handleOutsideClick);

      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, []);

    useEffect(() => {
      // Fetch the suggestions from the API based on the searchValue
      if (searchValue) {
        fetchSuggestionsFromApi()
          .then((data) => setSuggestions(data))
          .catch((error) => console.error('Error fetching suggestions:', error));
      } else {
        setSuggestions([]); // Clear suggestions if the searchValue is empty
      }
    }, [searchValue]);

    async function fetchSuggestionsFromApi() {
      try {
        const response = await fetch(`https://startuped.app/api/Search?Keyword=${searchValue}`);
        const data = await response.json();
        return data.map((item) => item.name); // Extract only the "name" field for suggestions
      } catch (error) {
        throw error;
      }
    }
    const startListening = () => SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });

    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  
    const handleSpeechRecognition = () => {
      if (listening) {
        SpeechRecognition.stopListening();
        setSearchValue(transcript); // Populate the recorded text into the search box
      } else {
        startListening();
      }
    };
    useEffect(() => {
      if (!listening) {
        resetTranscript(); // Reset the transcript when recording is stopped
      }
    }, [listening]);
  
    useEffect(() => {
      if (transcript) {
        setSearchValue(transcript);
      }
    }, [transcript]);

    if (!browserSupportsSpeechRecognition) {
        return null
    }

    return (
      <>
      <div className="bg-common">       
      <div className="bg-common-wrapper">
      <div class="blob"></div>

          <a href="/index">
            <img
              src={toAbsoluteUrl("/media/logos/StartupEd-Logo.png")}
              alt="Logo"
              className="search-page-logo-image"
            />
            </a>
            
            </div>
            
            <div className="search-page-content">
            
            <div className="search-page-logo"></div>
            <div className="search-page-subheading">Copilot for Startup Ecosystem(s)</div>
            <div className="search-page-search-box" ref={searchBoxRef}>
            
            <div className={`search-page-search-bar`}>
            <input
            type="text"
            placeholder="Ex. Sequoia Capital"
            className="search-page-input"
            value={searchValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            />
            <button
            className={`microphone-button ${listening ? 'active' : ''}`}
            onClick={handleSpeechRecognition}
            >
            <i className={`bi bi-mic`} style={{ fontSize: '24px' }}></i>
            </button>
            </div>
            
              <div className="search-page-entity-box">
                {ENTITY_DATA.map((entityItem) => (
                  <div
                    key={entityItem.name}
                    className={`search-page-entity${selectedEntities.includes(entityItem.name) ? ' selected' : ''}`}
                    onClick={() => toggleEntitySelection(entityItem.name)}
                  >
                    {entityItem.label}
                  </div>
                ))}
              </div>

              {loading ? (
                // Show the Metronic loading screen while data is being fetched
                <div className="metronic-loading">
                  <span className="spinner-border spinner-border-sm align-center"></span>
                  <span className="ml-2 align-center">Loading...</span>
                </div>
              ) : (
                // Show the search window with suggestions when loading is false
                showSearchWindow && (
                  <SearchWindow
                    show={showSearchWindow}
                    items={suggestions}
                    onItemClick={handleSearchWindowSelect}
                  />
                )
              )}
            </div>

          </div>
        </div>
        <div className="buy-button">
    <div className="button-group">
      {/* Login Button */}
      <div className="sign-in-button">
        <a href="https://startuped.app/signin" className="btn btn-secondary btn-sm" target="_blank"  rel="noreferrer">
          <img
            src={toAbsoluteUrl("/media/svg/startupedSvg/user.svg")}
            alt="Login Icon"
            className="login-icon"
          />
        </a>
      </div>
      <div className='suport-btn-1'>
      {/* Support Button */}
      <a href="/buy" className="btn btn-primary btn-sm" target="_blank"  rel="noreferrer">
       <i className="fas fa-heart  mt-n1"></i> Support Us
      </a>
    </div>
</div>
  </div>
  
      <div className="footer-links-Sp">
      <a href="https://www.startuped.net/privacy" target='_blank' rel="noreferrer">
      Privacy
    </a>
    <a href="https://www.startuped.net/terms-and-use" target='_blank' rel="noreferrer">
      Terms
    </a>
    <a href="https://www.startuped.net/faq" target='_blank' rel="noreferrer">
      FAQ
    </a>
    <a href="https://www.startuped.net/contact" target='_blank' rel="noreferrer">
      Contact Us
    </a>
  </div>
  

      </>
    );
  };

  export default SearchPage;

import React, { useCallback, useState } from 'react';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

const surveyJson = {
  elements: [
    {
      name: 'FirstName',
      title: 'Enter your first name:',
      type: 'text',
      isRequired: true
    },
    {
      name: 'LastName',
      title: 'Enter your last name:',
      type: 'text',
      isRequired: true
    },
    {
      name: 'Email',
      title: 'Enter your email:',
      type: 'text',
      inputType: 'email',
      isRequired: true,
      validators: [
        {
          type: 'email'
        }
      ]
    },
    {
      name: 'Rating',
      title: 'Rate your experience (1-5):',
      type: 'rating',
      minRateDescription: 'Bad',
      maxRateDescription: 'Excellent',
      isRequired: true
    },
    {
      name: 'Feedback',
      title: 'Provide feedback:',
      type: 'comment',
      isRequired: true
    }
  ]
};

const SurveyPage = () => {
  const [surveyResult, setSurveyResult] = useState('');

  const handleSurveyComplete = useCallback((sender: any) => {
    const results = JSON.stringify(sender.data);
    setSurveyResult(results);
  }, []);

  return (
    <div>
      <Survey model={new Model(surveyJson)} onComplete={handleSurveyComplete} />
      {surveyResult && <div>Survey Result: {surveyResult}</div>}
    </div>
  );
};

export default SurveyPage;

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import SearchPage from '../modules/Search/SearchPage';
import SearchResultsPage from '../modules/Search/SearchResultsPage';
import BuyPage from '../modules/Buy/BuyPage';
import ChatBox from '../modules/ChatWindow/ChatBox'
import Index from '../modules/Home'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */


const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename="/">
    <Routes>  
      <Route element={<App />}>
        /* No auth needed */
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        <Route path="/talk" element={<SearchPage/>} />
        <Route path="/search-results" element={<SearchResultsPage/>} />
        <Route path="/ai" element={<ChatBox/>} />
        <Route path="/buy" element={<BuyPage/>} />

        
        {currentUser ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
          </>
        ) : (
          <>
            <Route index element={<Index/>} />
            <Route path="/index" element={<Index/>} />

            <Route path="/search-results" element={<SearchResultsPage/>} />
            <Route path="/buy" element={<BuyPage/>} />
            <Route path='auth/*' element={<AuthPage />} />
            <Route path='*' element={<Navigate to='/auth' />} />
          </>
        )}
      </Route>
    </Routes>
  </BrowserRouter>
  )
}

export {AppRoutes}

// import React, { useState } from 'react';

// const App = () => {
//   const [note, setNote] = useState('');
//   const [alertMessage, setAlertMessage] = useState('');

//   const handleSaveNote = () => {
//     // Make an API call to your back-end server to save the note data
//     fetch("http://localhost:3001/save-note", {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ note }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log('Note saved:', data);
//         setAlertMessage('Note saved successfully');
//       })
//       .catch((error) => {
//         console.error('Error saving note:', error);
//         setAlertMessage('Failed to save note');
//       });
//   };

//   return (
//     <div className="container mt-4">
//       {alertMessage && (
//         <div className="alert alert-success" role="alert">
//           {alertMessage}
//         </div>
//       )}
//       <div className="mb-3">
//         <label htmlFor="noteTextarea" className="form-label">Note</label>
//         <textarea
//           className="form-control"
//           id="noteTextarea"
//           value={note}
//           onChange={(e) => setNote(e.target.value)}
//         ></textarea>
//       </div>
//       <button className="btn btn-primary" onClick={handleSaveNote}>Save Note</button>
//     </div>
//   );
// };

// export default App;

import React from 'react'

export default function notes() {
  return (
    <iframe src="https://yoychen.github.io/notence/" title="External Website" width="100%" height="500px" /> 
  )
}


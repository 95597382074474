import React, { useEffect, useRef, useState } from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css';
// import { shellParser } from 'node-shell-parser';
import axios from 'axios';


const saveTerminalDataUrl = '/save-terminal-data';

const TerminalLogic = () => {
  const terminalRef = useRef(null);
  const fitAddonRef = useRef(null);
  const commandRef = useRef('');
  const commandHistoryRef = useRef([]);
  const [terminal, setTerminals] = useState([]);

  useEffect(() => {
    const newTerminal = new Terminal({
      cursorBlink: true, // Enable blinking cursor
    });
    fitAddonRef.current = new FitAddon(); // Create a FitAddon for terminal resizing
    newTerminal.loadAddon(fitAddonRef.current);
    newTerminal.open(terminalRef.current);

    newTerminal.writeln('Welcome to Negril JS Terminal!\r\n');
    newTerminal.write('\x1B[33mType "help" to get started:\x1B[0m '); // Apply styling for the prompt

    const handleUserInput = async (event) => {
      const input = event.key;

      if (input === '\r') {
        const command = commandRef.current;
        commandHistoryRef.current.push(command);

        switch (command) {
          case 'help':
            newTerminal.writeln('\r\n\x1B[36mAvailable Commands:\x1B[0m');
            newTerminal.writeln('\x1B[35m- echo <text>: \x1B[0mDisplay the entered text');
            newTerminal.writeln('\x1B[35m- fetch <query>: \x1B[0mMake an API request with the query');
            newTerminal.writeln('\x1B[35m- history: \x1B[0mDisplay command history');
            newTerminal.writeln('\x1B[35m- clear: \x1B[0mClear the terminal');
            break;
          case 'history':
            newTerminal.writeln('\r\n\x1B[36mCommand History:\x1B[0m');
            newTerminal.writeln(commandHistoryRef.current.join('\r\n'));
            break;
          case 'clear':
            newTerminal.clear();
            newTerminal.writeln('\x1B[33mWelcome to Negril JS Terminal!\r');
            newTerminal.write('\x1B[33mType "help" to get started:\x1B[0m ');
            break;
          case 'echo':
            newTerminal.write('\r\n\x1B[33mType "echo" and then the text to display:\x1B[0m ');
            break;
          case 'fetch':
            newTerminal.write('\r\n\x1B[33mType "fetch" and then the query:\x1B[0m ');
            break;
          default:
            if (command.startsWith('echo')) {
              const text = command.slice(5);
              newTerminal.writeln('\r\nText entered: ' + text);
            } else if (command.startsWith('fetch')) {
              const query = command.slice(6);
              const apiUrl = 'https://api.wizar.startuped.xyz/api/v1/Gpt/MyBot';
              const url = apiUrl + '/' + encodeURIComponent(query);
              newTerminal.writeln('\r\nWaiting for API Request: ' + url);

              try {
                const response = await axios.post(url, { query });

                if (response.status === 200) {
                  const data = response.data;
                  newTerminal.writeln('\x1B[36mAPI Response:\x1B[0m ' + JSON.stringify(data));

                  const terminalId = newTerminal._core._viewportElement.parentElement.id;
                  const history = commandHistoryRef.current.join('\n');
                  if (command.trim() !== '') {
                    saveTerminalData(terminalId, history, data);
                  }
                } else {
                  throw new Error('API Request Error: ' + response.statusText);
                }
              } catch (error) {
                newTerminal.writeln(error.message);
              }
            } else {
              newTerminal.writeln('\r\n\x1B[31mInvalid command: ' + command + '\x1B[0m');
            }

            newTerminal.write('\x1B[33mType "help":\x1B[0m ');
            break;
        }

        commandRef.current = '';
      } else if (input === '\x7F') {
        commandRef.current = commandRef.current.slice(0, -1);
        newTerminal.write('\b \b');
      } else {
        commandRef.current += input;
        newTerminal.write(input);
      }
    };

    newTerminal.onKey(handleUserInput);
    fitAddonRef.current.fit(); // Fit the terminal to the container
    setTerminals((prevTerminals) => [...prevTerminals, newTerminal]);

    return () => {
      newTerminal.dispose();
      setTerminals((prevTerminals) => prevTerminals.filter((t) => t !== newTerminal));

      const terminalId = newTerminal._core._viewportElement.parentElement.id;
      const history = commandHistoryRef.current.join('\n');
      const apiResponse = '';
      if (commandHistoryRef.current.length > 0) {
        saveTerminalData(terminalId, history, apiResponse);
      }
    };
  }, []);

  const saveTerminalData = async (terminalId, history, apiResponse) => {
    try {
      await axios.post(saveTerminalDataUrl, { terminalId, history, api_response: apiResponse });
      console.log('Terminal Session Data saved successfully');
    } catch (error) {
      console.log('Error saving Terminal Session Data:', error);
    }
  };

  // const processShellOutput = (shellOutput) => {
  //   const rows = shellParser(shellOutput, { separator: ' ', skipLines: 1 });
  //   terminals.forEach((terminal) => {
  //     terminal.writeln('\r\n\x1B[36mParsed Output:\x1B[0m');
  //     terminal.writeln(JSON.stringify(rows));
  //   });
  // };
  
  return <div ref={terminalRef} className="terminal" />;
};

export default TerminalLogic;

import React from 'react'
import './styles/style.css';
// import  htmlContent from './content.html';


export default function windows() {
  return (
    <div>
{/* <Content/>     */}
<iframe src="https://win11.vercel.app/" title="External Website" width="100%" height="500px" />  

</div>
  )
}

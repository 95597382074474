import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './styles/searchResultsPage.css';
import SearchResultsList from './components/SearchResultsList';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import SearchWindow from './components/SearchWindow'; 
import ChatBox from '../ChatWindow/ChatBox';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Pagination from 'react-bootstrap/Pagination';




const SearchResultsPage = () => {
  const location = useLocation();
  const initialSearchQuery = new URLSearchParams(location.search).get('query');
  const initialEntityTypes = new URLSearchParams(location.search).get('entity'); 

  const [searchQuery, setSearchQuery] = useState(initialSearchQuery || ''); // Initialize with the query from the URL
  const [entityTypes, setEntityTypes] = useState(initialEntityTypes || ''); // Initialize with entity types from the URL

  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentLocation, setCurrentLocation] = useState('Fetching location...');
  const [suggestions, setSuggestions] = useState([]); // State for dynamic suggestions from the API
  const [shouldFetchDescription, setShouldFetchDescription] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState('prod');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    fetchSearchResults(searchQuery, entityTypes, selectedEnvironment ,currentPage, pageSize)
      .then((data) => {
        setSearchResults(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
        setLoading(false);
      });

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
        },
        (error) => {
          console.error('Error getting current location:', error);
        }
      );
    } else {
      console.log('Geolocation not available.');
    }
  }, []);

  const getIpAddress = async () => {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json');
      return response.data.ip;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      return 'Unknown';
    }
  };
  
  const sendSlackMessage = (message, ipAddress) => {
    const serverUrl = '/api/send-slack-message'; // Replace with your server URL
    
    // Post the message and IP address to your server
    axios.post(serverUrl, { message, ipAddress })
      .then(() => {
        console.log('Message and IP address sent to server successfully.');
      })
      .catch((error) => {
        console.error('Error sending message and IP address to server:', error);
      });
  };

  const fetchSearchResults = async (query, entityTypes, environment, pageNumber, pageSize) => {
    try {
      const searchParams = new URLSearchParams({
        Keyword: query,
        pageNumber: pageNumber,
        pageSize: pageSize

      });
      if (entityTypes) {
        searchParams.append('Entity', entityTypes);
      }

      const baseUrl = environment === 'dev' ? 'https://startuped.dev/api/Search' : 'https://startuped.app/api/Search';
      const response = await fetch(`${baseUrl}?${searchParams}`);
      const data = await response.json();
      setTotalPages(Math.ceil(data.length / pageSize));

      return data;
    } catch (error) {
      throw error;
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);
  };
   const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);

      fetchSearchResults(searchQuery, entityTypes, selectedEnvironment,currentPage, pageSize)
        .then((data) => {
          setSearchResults(data);
          setLoading(false);
          
        if (searchQuery.trim() !== '') {
          sendSlackMessage(searchQuery);
        }
        })
        .catch((error) => {
          console.error('Error fetching search results:', error);
          setLoading(false);
        });
    }
  };
  const handlePageChange = (selectedPage) => {
    setLoading(true);
  
    let newPage = currentPage;
  
    if (selectedPage === 'previous') {
      newPage = currentPage - 1;
      if (newPage < 1) {
        newPage = 1;
      }
    } else if (selectedPage === 'next') {
      newPage = currentPage + 1;
      if (newPage > totalPages) {
        newPage = totalPages;
      }
    } else {
      newPage = selectedPage;
    }
  
    console.log('Previous page:', currentPage);
    setCurrentPage(newPage);
    console.log('New page:', newPage);
  
    fetchSearchResults(searchQuery, entityTypes, selectedEnvironment, newPage, pageSize)
      .then((data) => {
        setSearchResults(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
        setLoading(false);
      });
  };
  
  
  const handleSearchWindowSelect = (item) => {
    setSearchQuery(item);
    setSuggestions([]); // Clear suggestions after selecting an item
  };

  // const fetchSuggestionsFromApi = async (inputValue) => {
  //   try {
  //     const response = await fetch(`https://startuped.dev/api/Search?Keyword=${inputValue}`);
  //     const data = await response.json();
  //     return data.map((item) => item.name); // Extract only the "name" field for suggestions
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  const handleEntityFilterClick = (selectedEntityType, environment, page, pageSize) => {
    setEntityTypes(selectedEntityType);
    setSelectedEnvironment(environment);
    setLoading(true);
  
    const newPage = 1; // Set the page number to 1
  
    fetchSearchResults(searchQuery, selectedEntityType, environment, newPage, pageSize)
      .then((data) => {
        setSearchResults(data);
        setLoading(false);
        setCurrentPage(newPage); // Set the current page to 1
      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
        setLoading(false);
      });
  };
  
  

  const handleSearchIconClick = () => {
    setLoading(true);

    fetchSearchResults(searchQuery, entityTypes, selectedEnvironment,currentPage, pageSize)
      .then((data) => {
        setSearchResults(data);
        setLoading(false);
        if (searchQuery.trim() !== '') {
          sendSlackMessage(searchQuery);
        }
      
      })

      .catch((error) => {
        console.error('Error fetching search results:', error);
        setLoading(false);
      });
  };
  const startListening = () => SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });

  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  const handleSpeechRecognition = () => {
    if (listening) {
      SpeechRecognition.stopListening();
      setSearchQuery(transcript); // Populate the recorded text into the search box
    } else {
      startListening();
    }
  };
  useEffect(() => {
    if (!listening) {
      resetTranscript(); // Reset the transcript when recording is stopped
    }
  }, [listening]);

  useEffect(() => {
    if (transcript) {
      setSearchQuery(transcript);
    }
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
      return null
  }
  
  return (<>
   

    <div className="search-results-page">

    

      <div id="header">
      <div className="dropdown-container">
      {/* Dropdown menu code */}
      <div className="buy-button">
        <div className="button-group">
          {/* Login Button */}
          <div className="sign-in-button">
            <a href="https://startuped.app/signin" className="btn btn-secondary btn-sm" target="_blank" rel="noreferrer">
              <img
                src={toAbsoluteUrl("/media/svg/startupedSvg/user.svg")}
                alt="Login Icon"
                className="login-icon"
              />
            </a>
          </div>
          <div className='suport-btn-1'>
            {/* Support Button */}
            <a href="/buy" className="btn btn-primary btn-sm" target="_blank" rel="noreferrer">
              <i className="fas fa-heart mt-n1"></i> Support Us
            </a>
          </div>
        </div>
       
    
      </div>
     </div>
    
      
        <div id="topbar">
       
        
    
        
  
          <a href="/talk">
            <img id="searchbarimage" src={toAbsoluteUrl("/media/logos/StartupEd-Logo.png")} alt="Startuped Logo" />
          </a>
          <div id="searchbar" type="text">
            <input
              id="searchbartext"
              type="text"
              value={searchQuery}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            <button id="searchbarbutton" onClick={handleSearchIconClick}>
              <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
              </svg>
            </button>
            <button
            className={`microphone-button ${listening ? 'active' : ''}`}
            onClick={handleSpeechRecognition}
            >
            <i className={`bi bi-mic`} style={{ fontSize: '24px' }}></i>
            </button>
            {/* Show the SearchWindow component with suggestions */}
            <SearchWindow show={!!suggestions.length} items={suggestions} onItemClick={handleSearchWindowSelect} />
          </div>
        </div>
        <div id="optionsbar">
        <ul id="optionsmenu1">
        <li
          className={entityTypes === '' ? 'active' : ''}
          onClick={() => handleEntityFilterClick('', selectedEnvironment,currentPage, pageSize)}
        >
          All
        </li>
        <li
          className={entityTypes === 'InvestorContact' ? 'active' : ''}
          onClick={() => handleEntityFilterClick('InvestorContact', selectedEnvironment,currentPage, pageSize)}
        >
          Investor
        </li>
        <li
          className={entityTypes === 'IncubationProgram' ? 'active' : ''}
          onClick={() => handleEntityFilterClick('IncubationProgram', selectedEnvironment,currentPage, pageSize)}
        >
          Incubators
        </li>
        <li
          className={entityTypes === 'Web3funds' ? 'active' : ''}
          onClick={() => handleEntityFilterClick('Web3funds', selectedEnvironment,currentPage, pageSize)}
        >
          Web3 Funds
        </li>
        <li
          className={entityTypes === 'grant' ? 'active' : ''}
          onClick={() => handleEntityFilterClick('grant', selectedEnvironment,currentPage, pageSize)}
        >
          Grant
        </li>
        <li
          className={entityTypes === 'event' ? 'active' : ''}
          onClick={() => handleEntityFilterClick('event', selectedEnvironment,currentPage, pageSize)}
        >
          Event
        </li>
          </ul>
         
        
        </div>
      </div>
      <div id="searchresultsarea">
      <div id="descriptionboxarea">
      <ChatBox /> 
      
      </div>
     
      {loading ? (
        <div  className="loading-container">
          <Skeleton height={200} width={200} />
          <Skeleton count={20} />
        </div>
        ) : (
          <SearchResultsList searchResults={searchResults} searchQuery={searchQuery} loading={loading} entityTypes={entityTypes}/>
          
          
          )}
         
      </div>
     
      <Pagination className='pagination'>
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      <Pagination.Item active>{currentPage}</Pagination.Item>
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
      </Pagination>
      <div id="footer" className="footer-section">
      
      
        <div className="footer-location">
          <p>Latitude: {currentLocation.latitude} || Longitude: {currentLocation.longitude} </p>
          
        </div>
        <div className="footer-copyright">
        &copy; StartupEd 2023
      </div>
       
        <div className="footer-links">

          <a href="https://www.startuped.net/privacy" target='_blank' rel="noreferrer">
            Privacy
          </a>
          <a href="https://www.startuped.net/terms-and-use" target='_blank' rel="noreferrer">
            Terms
          </a>
          <a href="https://www.startuped.net/faq" target='_blank' rel="noreferrer">
            FAQ
          </a>
          <a href="https://www.startuped.net/contact" target='_blank'>
            Contact Us
          </a>
        </div>        <button  onClick={() => {
          const enteredKeyword = window.prompt('Enter the keyword:');
          if (enteredKeyword === 'admin@startuped') {
            // Display the 'Settings' dropdown menu
            document.getElementById('dropdown-menu').style.display = 'block';
          } else {
            // Show an alert message if the keyword doesn't match
            window.alert('Incorrect keyword. Please try again.');
          }
        }} className='btn btn-sm'>
           Settings
        </button>
        <ul className="" id="dropdown-menu" style={{ display: 'none' }}>
          <li onClick={() => handleEntityFilterClick(entityTypes, 'prod')}>Prod</li>
          <li onClick={() => handleEntityFilterClick(entityTypes, 'dev')}>Dev</li>
        </ul>
        
      </div>

    </div>
  
    </>
  );
};

export default SearchResultsPage;
import React from 'react'
import { ReactFlowProvider } from 'reactflow';
import useStore from './ZustandStore'; // Import your Zustand store

import FlowPage from './FlowPage'

export default function drawflow() {
 

  return (
  //   <ReactFlowProvider>
  //   <FlowPage />
  // </ReactFlowProvider>
  <iframe 
  src="https://jerosoler.github.io/Drawflow/" 
  style={{width: "100%", height: "600px"}} 
  title="Drawflow"
/>

  )
}

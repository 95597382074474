import axios from 'axios';
import { AuthModel, UserModel } from './_models';

export const GET_USER_BY_ACCESSTOKEN_URL = `/api/verify_token`

export const LOGIN_URL = `/api/login`;
export const REGISTER_URL = `/api/register`;
export const FORGOT_PASSWORD_URL = `/api/forgot_password`;

export function login(username: string, password: string): Promise<AuthModel> {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
    .then(response => {
      console.log('Response from login:', response.data);
      return response.data;
    });
}

export function register(
  username: string,
  email: string,
  password: string,
  role: string
): Promise<AuthModel> {
  return axios.post<AuthModel>(REGISTER_URL, {
    username,
    email,
    password,
    role,
  })
    .then(response => response.data);
}

export function requestPassword(email: string): Promise<{ result: boolean }> {
  return axios.post<{ result: boolean }>(FORGOT_PASSWORD_URL, {
    email,
  })
    .then(response => response.data);
}

export async function getUserByToken(token: string): Promise<UserModel> {
  const response = await axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
  return response.data;
}

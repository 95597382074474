import React from 'react';
import { toAbsoluteUrl } from '../../../_metronic/helpers';


const Header = () => {
  return (
    <div className="text-center">
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <h1 className="display-4">Invest in StartupEd</h1>
          <p className="lead">Connect your Web3 wallet and purchase (IPT) to invest in the future.</p>
        </div>
        <div>
        <img src={toAbsoluteUrl("/media/Stripe.png")} alt="StartupEd Logo" className="laptopimg" />

        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useRef, useState } from 'react';
import { EmailEditor } from 'react-email-editor';

import axios from 'axios';

const UnLayerPage = () => {
  const emailEditorRef = useRef(null); // Use useRef without a type
  const [isSaved, setIsSaved] = useState(false); // State variable for success message
  const [error, setError] = useState(''); // State variable for error message

  const exportHtml = () => {
    if (emailEditorRef.current) {
      emailEditorRef.current.exportHtml((data) => {
        const { html } = data;
        console.log('exportHtml', html);

        axios
          .post('/saveHtml', { html })
          .then((response) => {
            console.log(response.data.message);
            setIsSaved(true); // Set the state to indicate success
            setError(''); // Clear any previous error message
          })
          .catch((error) => {
            console.error('Error saving HTML code: ', error);
            setIsSaved(false); // Set the state to indicate failure
            setError('Failed to save HTML code.'); // Set the error message
          });
      });
    }
  };

  const onLoad = () => {
    // Editor instance is created
    // You can load your template here
    // const templateJson = {};
    // emailEditorRef.current.loadDesign(templateJson);
  };

  const onReady = () => {
    // Editor is ready
    console.log('onReady');
  };

  return (
    <div>
      {isSaved && (
        <div className="alert alert-success" role="alert">
          HTML code saved successfully!
        </div>
      )}

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      <div>
        <button className="btn btn-primary" onClick={exportHtml}>
          Export HTML
        </button>
      </div>

      <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
    </div>
  );
};

export default UnLayerPage;

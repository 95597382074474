import {lazy, FC, Suspense} from "react"
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
  // StartupEd routes added here
// import Search1 from '../modules/Search/search1'

import WindowsDesktop from '../pages/windowsDesktop/windows';
import Xterm from '../pages/Xterm/xterm'
import Blockly from '../pages/Blockly/blockly'
import MacDesktop from '../pages/MacDesktop/mac'
import Monaco from '../pages/Monaco/monaco'
import Notes from '../pages/Notes/notes'
import UnLayer from '../pages/Unlayer/UnLayer'
import Survey from '../pages/Survey/survey'
import Editor from '../pages/Editor/editor'
import DrawFlow from '../pages/DrawFlow/drawflow'
import ImageEditor from '../pages/ImageEditor/imageEditor'
import SearchPage from '../modules/Search/SearchPage';
import Index from '../modules/Home'




const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))


  return (
    <Routes>
      {/* only component routes of NegrilJS */}
      <Route path='o/windows' element={<WindowsDesktop />} />
        <Route path='o/macDesktop' element={<MacDesktop />} />
        <Route path='o/xterm' element={<Xterm/>} />
        <Route path='o/blockly' element={<Blockly/>} />
        <Route path='o/monaco' element={<Monaco/>} />
        <Route path='o/notes' element={<Notes/>} />
        <Route path='o/unlayer' element={<UnLayer/>} />
        <Route path='o/survey' element={<Survey/>} />
        <Route path='o/editor' element={<Editor/>} />
        <Route path='o/drawflow' element={<DrawFlow/>} />
        <Route path='o/imageEditor' element={<ImageEditor/>} />
        {/* <Route  path="/talk" element={<SearchPage/>} /> */}
     
        <Route path='/' element={<SearchPage />} />
        <Route path='index' element={<Index/>}/>


      <Route element={<MasterLayout />}>

        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='windows' element={<WindowsDesktop />} />
        <Route path='macDesktop' element={<MacDesktop />} />
        <Route path='xterm' element={<Xterm/>} />
        <Route path='blockly' element={<Blockly/>} />
        <Route path='monaco' element={<Monaco/>} />
        <Route path='notes' element={<Notes/>} />
        <Route path='unlayer' element={<UnLayer/>} />
        <Route path='survey' element={<Survey/>} />
        <Route path='editor' element={<Editor/>} />
        <Route path='drawflow' element={<DrawFlow/>} />
        <Route path='imageEditor' element={<ImageEditor/>} />

        

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
import Image from '@editorjs/image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';

const editorTools = {
  header: Header,
  list: List,
  embed: Embed,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          // Your upload logic here
          return new Promise((resolve, reject) => {
            // Simulating image upload delay
            setTimeout(() => {
              // Assuming the image URL is returned after upload
              const imageURL = 'https://example.com/image.jpg';
              resolve({
                success: 1,
                file: {
                  url: imageURL,
                },
              });
            }, 2000);
          });
        },
      },
    },
  },
  quote: Quote,
  table: Table,
  code: Code,
  inlineCode: InlineCode,
  raw: Raw,
  delimiter: Delimiter,
  checklist: CheckList,
  warning: Warning,
  link: LinkTool,
  marker: Marker,
  simpleImage: SimpleImage,
};

export default editorTools;

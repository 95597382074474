import React, { useState } from 'react';
import axios from 'axios';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';



import './styles.css';

export default function ChatBox() {
  const [messages, setMessages] = useState([
    {
      role: 'StartupEd Copilot',
      content: 'Hi, Myself StartupEd AI Chat! Go ahead and send me a message. 😄',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    },
  ]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => setIsChatOpen(!isChatOpen);

  const sendMessage = async () => {
    if (inputText.trim() === '') {
      return;
    }

    const newMessage = {
      role: 'user',
      content: inputText,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputText('');

    setIsLoading(true);

    try {
      const apiUrl = 'https://api.wizar.startuped.xyz/api/v1/Gpt/MyBot';
      const payload = [{ role: 'user', content: inputText }];

      const response = await axios.post(apiUrl, payload);
      const responseData = response.data;

      console.log('API Response:', response);
      console.log('Response Data:', responseData);

      const botResponse = {
        role: 'StartupEd Copilot',
        content: responseData.content,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        data: responseData,
      };

      setMessages((prevMessages) => [...prevMessages, botResponse]);
    } catch (error) {
      console.error('Error fetching description:', error);
      // Handle error here
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <div className='chatbox'>
      <div className='container-vertical'>
        <div className="vertical-line"></div> {/* Add this line */}
      <button className="toggle-button" onClick={toggleChat}>
      <i class="fa fa-angle-double-right"></i>Talk To CoPilot!</button>
      </div>
        {isChatOpen ? (
          <section className="msger">
  {/*          <button className="close-button" onClick={toggleChat}>Close</button>
        */}         <main className="msger-chat">
        {isLoading && (
          <div className="loading-container">
          <Skeleton height={100} width={150} />
          <Skeleton count={2} />
        </div>
      )}
              {messages.map((message, index) => (
                
                <div key={index} className={`msg ${message.role === 'StartupEd Copilot' ? 'right-msg' : 'left-msg'}`}>
                
                <div className={`msg-img ${message.role === 'user' ? 'user-img' : 'bot-img'}`} style={{ backgroundImage: `url(${message.role === 'user' ? toAbsoluteUrl("/media/blank.png") : toAbsoluteUrl("/media/logos/StartupEdLogo_small.png")})` }}></div>
                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-name">{message.role === 'user' ? 'You' : 'StartupEd Copilot'}</div>
                      <div className="msg-info-time">{message.time}</div>
                    </div>
                    <div className="msg-text">{message.content}</div>
                    {message.data && (
                      <div className="msg-data">
                        <pre>{JSON.stringify(message.data, null, 2)}</pre>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </main>

          <form className="msger-inputarea" onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              className="msger-input"
              placeholder="Enter your message..."
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  sendMessage();
                }
              }}
            />
            <button type="submit" className="msger-send-btn" onClick={sendMessage}>
              Send
            </button>
          </form>
        </section>
        
      ) : (
        null
      )}
    </div>
  );
}

import React from 'react';

const TerminalUI = ({ onNewTerminalClick, terminals, onCloseTerminalClick }) => {
  return (
    <div className="mt-5">
      {terminals.map((terminal, index) => (
        <div key={index} className="mb-3">
          {terminal}
          <button className="btn btn-danger" onClick={() => onCloseTerminalClick(index)}>Close Terminal</button>
        </div>
      ))}
      <div className="d-grid gap-2">
        <button className="btn btn-primary" onClick={onNewTerminalClick}>Open New Terminal</button>
      </div>
    </div>
  );
};

export default TerminalUI;

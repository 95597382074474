import React, { useEffect, useRef } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
const TuiImageEditor = require('tui-image-editor');

const MyTheme = {
  // Your custom theme object here
};

const ImageEditorComponent = () => {
  const editorRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    editorRef.current = new TuiImageEditor(containerRef.current, {
      includeUI: {
        loadImage: {
          path: '', // Replace this with the path to your image
          name: 'SampleImage',
        },
        theme: MyTheme,
        menu: ['shape', 'filter', 'text', 'crop', 'icon', 'draw', 'mask', 'rotate'],
        initMenu: 'filter',
        uiSize: {
          width: '1000px',
          height: '700px',
        },
        menuBarPosition: 'bottom',
      },
      cssMaxHeight: 500,
      cssMaxWidth: 700,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },
      usageStatistics: true,
    });
  }, []);



  return (
      <div ref={containerRef} style={{ width: '1000px', height: '700px' }}>
          
            </div>  );
};

export default ImageEditorComponent;

import React, { useEffect, useRef } from 'react';
import Blockly from 'blockly';
import CodeGenerator from './generator/generator';
import { javascriptGenerator } from 'blockly/javascript';





const BlocklyComponent = () => {
  const blocklyDivRef = useRef(null);
  const workspaceRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    const newWorkspace = Blockly.inject(blocklyDivRef.current, {
      toolbox: createToolboxXml(),
      readOnly: false,
      trashcan: true,
      media: 'media/',
      move: {
        scrollbars: true,
        drag: true,
        wheel: true,
      },
      initialXml: `
        <xml xmlns="http://www.w3.org/1999/xhtml">
          <block type="controls_ifelse" x="0" y="0"></block>
        </xml>
      `,
    });
    workspaceRef.current = newWorkspace;

    const updateCode = () => {
      if (workspaceRef.current) {
        const code = javascriptGenerator.workspaceToCode(workspaceRef.current);
        textareaRef.current.value = code;
      }
    };

    workspaceRef.current.addChangeListener(updateCode);

    return () => {
      workspaceRef.current.dispose();
    };
  }, []);

  const createToolboxXml = () => {
    const toolboxXml = Blockly.utils.xml.textToDom(`
      <xml xmlns="http://www.w3.org/1999/xhtml">
        <category name="Logic" colour="#5C81A6">
          <block type="controls_if"></block>
          <block type="logic_compare"></block>
        </category>
        <category name="Loops" colour="#5CA65C">
          <block type="controls_repeat_ext">
            <value name="TIMES">
              <shadow type="math_number">
                <field name="NUM">10</field>
              </shadow>
            </value>
          </block>
        </category>
        <category name="Math" colour="#DD2C00">
          <block type="math_number"></block>
          <block type="math_arithmetic">
            <field name="OP">ADD</field>
            <value name="A">
              <shadow type="math_number">
                <field name="NUM">1</field>
              </shadow>
            </value>
            <value name="B">
              <shadow type="math_number">
                <field name="NUM">1</field>
              </shadow>
            </value>
          </block>
        </category>
        <category name="Text" colour="#FF4081">
          <block type="text"></block>
          <block type="text_length"></block>
          <block type="text_print">
          <value name="TEXT">
            <shadow type="text">
              <field name="TEXT">Hello, world!</field>
            </shadow>
            
          </value>
        </block>
        </category>
        <category name="Lists" colour="#4CAF50">
      <block type="lists_create_empty"></block>
      <block type="lists_create_with"></block>
      <!-- Add more list blocks here -->
    </category>
    <category name="Colour" colour="#039BE5">
      <block type="colour_picker"></block>
      <block type="colour_random"></block>
      <!-- Add more colour blocks here -->
    </category>
    <category name="Variables" colour="#9C27B0" custom="VARIABLE"></category>
      </xml>
    `);

    return toolboxXml;
  };

  const showCode = () => {
    const code = javascriptGenerator.workspaceToCode(workspaceRef.current);
    alert(code);
  };

  const runCode = () => {
    const code = javascriptGenerator.workspaceToCode(workspaceRef.current);
    
    try {
      new Function(code)();
    } catch (e) {
      alert(e);
    }
  };
  
  return (
    <div>
      <div
        id="blocklyDiv"
        style={{ height: '400px', width: '100%' }}
        ref={blocklyDivRef}
      ></div>
      <CodeGenerator />
      <textarea
      id="textarea"
      style={{ width: '100%', height: '200px', border: '2px solid blue', borderRadius: '5px', margin: '6px' }}
      ref={textareaRef}
    ></textarea>
    
     
    <button  className="btn btn-success" onClick={runCode}>
    Run Code
    </button>
    
      <button  className="btn btn-primary" onClick={showCode}>
  Show Code
  </button>
   
    </div>
  );
};

export default BlocklyComponent;
// import necessary packages and components
import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { getUserByToken, login } from '../core/_requests';
import { useAuth } from '../core/Auth';
import {toAbsoluteUrl} from '../../../../_metronic/helpers'


// define Yup validation schema for formik
const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
});

// define initial form values
const initialValues = {
  username: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { saveAuth, setCurrentUser } = useAuth();


  // set up formik form
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        // perform login and save authentication
        const auth = await login(values.username, values.password);
        saveAuth(auth);
        const user = await getUserByToken(auth.api_token);
        setCurrentUser(user);


    
      } catch (error) {
        console.error(error);
        console.log("Logged In failed")
        saveAuth(undefined);
        console.error(error);
        setErrorMessage('Invalid username or password. Please try again.');      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
    
  });

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* Form fields */}
      <img alt='Logo' src={toAbsoluteUrl('/media/logos/StartupEdLogo_small.png')} className='h-150px' style={{ position: 'absolute', transform: 'translateX(200%)' }} />

      <div className='fv-row mb-8'>
        
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.username && formik.errors.username },
            { 'is-valid': formik.touched.username && !formik.errors.username }
          )}
          type='text'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.password && formik.errors.password },
            { 'is-valid': formik.touched.password && !formik.errors.password }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      {/* Submit button */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {errorMessage && (
    <div className='alert alert-danger mt-5' role='alert'>
      {errorMessage}
    </div>
  )}
      </div>

      {/* Signup link */}
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  );
}

import React from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import '../styles/searchResultsPage.css'
export default function ZeroResultBox() {
  const suggestedKeywords = ['Capital', 'Seed', 'Venture'];

  return (
    <div className="zero-result-box">
    
      <p>No results available. Try searching with one of these keywords:</p>
      <ul>
        {suggestedKeywords.map((keyword, index) => (
          <li key={index}>{keyword}</li>
        ))}
      </ul>
      <img
    src={toAbsoluteUrl("/media/no-results.png")} // Set the default icon image path
    alt="no-results"
  />
    </div>
  );
}

import React, { useState, useRef } from 'react';
import TerminalLogic from './TerminalLogic';
import TerminalUI from './TerminalUI';

const TerminalComponent = () => {
  const [terminals, setTerminals] = useState([]);
  const terminalCounterRef = useRef(0); // Define and initialize the terminal counter

  const createTerminal = () => {
    const terminalId = terminalCounterRef.current; // Assign a unique terminal ID
    terminalCounterRef.current += 1; // Increment the terminal counter

    const terminal = <TerminalLogic key={terminalId} />;
    setTerminals((prevState) => [...prevState, terminal]);
  };

  const handleNewTerminalClick = () => {
    createTerminal();
  };

  const handleCloseTerminalClick = (index) => {
    setTerminals((prevState) => {
      const newTerminals = [...prevState];
      newTerminals.splice(index, 1);
      return newTerminals;
    });
  };

  return (
    <div>
      <TerminalUI
        onNewTerminalClick={handleNewTerminalClick}
        terminals={terminals}
        onCloseTerminalClick={handleCloseTerminalClick}
      />
    </div>
  );
};

export default TerminalComponent;

import React from 'react';

const SearchWindow = ({ show, items, onItemClick }) => {
    const handleItemClick = (item) => {
      onItemClick(item);
    };
  
    return show ? (
      <div className="search-window">
        {/* Add the heading */}
        <div className="search-window-item heading">Suggestions:</div>
        {items.map((item, index) => (
          <div key={index} className="search-window-item" onClick={() => handleItemClick(item)}>
            {item}
          </div>
        ))}
        
      </div>
    ) : null;
  };
  
  export default SearchWindow;
  
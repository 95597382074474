import React, { useRef, useEffect, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import editorTools from './editorTools';
import axios from 'axios';

const EditorPage = () => {
  const editorRef = useRef(null);
  const [saveStatus, setSaveStatus] = useState(null);

  useEffect(() => {
    let editorInstance = null;

    const initializeEditor = async () => {
      // Create a new instance of EditorJS when the component mounts
      editorInstance = await new EditorJS({
        holder: 'editorjs',
        autofocus: true,
        minHeight: 500,
        tools: editorTools,
        data: {
          blocks: [],
        },
        onReady: () => {
          console.log('Editor.js is ready!');
        },
        onChange: () => {
          console.log('Content has changed!');
        },
      });
      editorRef.current = editorInstance;

    };

    initializeEditor();

    // Cleanup function to destroy the Editor instance when the component unmounts
    return () => {
      if (editorInstance) {
        editorInstance.destroy();
      }
    };
  }, []);

  const saveContent = async () => {
    try {
      const savedData = await editorRef.current.save();

      // Add timestamp to the data
      const timestamp = Date.now();
      const data = {
        content: savedData,
        timestamp: timestamp,
      };

      // Send the data to the backend
      await axios.post('/save-content', data);

      setSaveStatus({ success: true, message: 'Content saved successfully' });
    } catch (error) {
      console.error('Error saving content:', error);
      setSaveStatus({ success: false, message: 'Failed to save content' });
    }
  };

  return (
    <div>
      {saveStatus && (
        <div
          className={`alert alert-${saveStatus.success ? 'success' : 'danger'}`}
          role="alert"
        >
          {saveStatus.message}
        </div>
      )}
      <div
        id="editorjs"
        style={{
          backgroundColor: '#FFFFFF',
          padding: '20px',
        }}
      ></div>
      <button onClick={saveContent} className="btn btn-primary">Save Content</button>
      </div>
  );
};

export default EditorPage;

import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { register } from '../core/_requests';
// import { toAbsoluteUrl } from '../../../../_metronic/helpers';

const registrationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  role: Yup.string().required('Role is required'),
});

const initialValues = {
  username: '',
  email: '',
  password: '',
  role: '',
};

export function Registration() {
  const [loading, setLoading] = useState(false);
const [alertMessage, setAlertMessage] = useState('');
const [isSuccess, setIsSuccess] = useState('');
  
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await register(values.username, values.email, values.password, values.role);
        setIsSuccess('Registration successful! Please login to continue.');
        formik.resetForm(); // Reset the form after successful registration
        setTimeout(() => {
          setAlertMessage('');
          navigate('/auth/login'); // Navigate to login page
        }, 3000);
      } catch (error: any) {
        console.error(error);
        if (error.response && error.response.data && error.response.data.message) {
          setAlertMessage(`Registration failed. ${error.response.data.message}`);
        } else if (error.response && error.response.data && error.response.data.error) {
          setAlertMessage(`Registration failed. ${error.response.data.error}`);
        } else {
          setAlertMessage('Registration failed. Please try again.');
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signup_form'>
      {/* Form fields */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.username && formik.errors.username },
            { 'is-valid': formik.touched.username && !formik.errors.username }
          )}
          type='text'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            { 'is-valid': formik.touched.email && !formik.errors.email }
          )}
          type='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Password</label>
        <input
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.password && formik.errors.password },
            { 'is-valid': formik.touched.password && !formik.errors.password }
          )}
          type='password'
          autoComplete='off'
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.password}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Role</label>
        <select
          {...formik.getFieldProps('role')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.role && formik.errors.role },
            { 'is-valid': formik.touched.role && !formik.errors.role }
          )}
        >
          <option value='' disabled>
            Select role
          </option>
          <option value='developer'>Developer</option>
          <option value='admin'>Admin</option>
          <option value='other'>Other</option>
        </select>
        {formik.touched.role && formik.errors.role && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>{formik.errors.role}</span>
          </div>
        )}
      </div>

      {/* Submit button */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_login_signup_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign up</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {/* Alert message */}
      {isSuccess && (
  <div className='alert alert-success mt-5' role='alert'>
    {isSuccess}
  </div>
)}

{/* Error message */}
{alertMessage && (
  <div className='alert alert-danger mt-5' role='alert'>
    {alertMessage}
  </div>
)}

      {/* Login link */}
      <div className='text-gray-500 text-center'>
        Already have an account?{' '}
        <Link to='/auth/login' className='link-primary'>
          Log in
        </Link>
      </div>
    </form>
  );
}

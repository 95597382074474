  /* global ethereum */

  import React, { useState, useEffect,useCallback } from 'react';
  import Header from './Header';
  import WalletSection from './WalletSection';
  // import BuySection from './BuySection';
  import './styles.css';
  import { Modal, Button } from 'react-bootstrap'; // Import Modal and Button from React Bootstrap

  import { toAbsoluteUrl } from '../../../_metronic/helpers';


  // import { connect, WalletConnection, keyStores } from 'near-api-js';

  // const NearConfig = {
  //   networkId: 'testnet', // Replace with 'mainnet' for production
  //   nodeUrl: 'https://rpc.testnet.near.org', // Replace with the mainnet RPC URL for production
  //   walletUrl: 'https://wallet.testnet.near.org', // Replace with the mainnet wallet URL for production
  //   contractName: 'YOUR_CONTRACT_NAME', // Replace with the name of your deployed smart contract
  // };


  const BuyPage = () => {
    const [connectedWalletAddress, setConnectedWalletAddress] = useState(null);
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [usdAmount, setUSDAmount] = useState(0);
    const [iptAmount, setIPTAmount] = useState(0);
    const [showModal, setShowModal] = useState(false);

    
    useEffect(() => {
      checkConnectedWallet();
    }, []);

    const checkConnectedWallet = async () => {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const accounts = await ethereum.request({ method: 'eth_accounts' });
          if (accounts.length > 0) {
            setConnectedWalletAddress(accounts[0]);
            setIsWalletConnected(true);
          } else {
            setIsWalletConnected(false);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    const connectWalletNear = async () => {
      // try {
      //   const near = await connect({
      //     networkId: NearConfig.networkId,
      //     nodeUrl: NearConfig.nodeUrl,
      //     walletUrl: NearConfig.walletUrl,
      //     deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() }, // Use BrowserLocalStorageKeyStore for web applications
      //   });
    
      //   // Create a new WalletConnection instance
      //   const wallet = new WalletConnection(near, 'YOUR_APP_NAME'); // Replace 'YOUR_APP_NAME' with a unique identifier for your app
    
      //   // Check if the user is already signed in
      //   if (!wallet.isSignedIn()) {
      //     // If not signed in, prompt the user to sign in
      //     await wallet.requestSignIn(NearConfig.contractName); // Use the contract name as the app title for better UX
      //   }
    
      //   // Update the wallet connection state
      //   setIsWalletConnected(true);
    
      //   // Fetch the user's account ID (wallet address)
      //   const accountId = wallet.getAccountId();
      //   setConnectedWalletAddress(accountId);
    
      //   // You can also call other Near-related functions or interact with your smart contract here
    
      // } catch (error) {
      //   console.error('Error connecting to Near wallet:', error);
      //   alert('There was an issue connecting to your Near wallet.');
      // }
    
    };

    const connectWalletMetaMask = async () => {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          setConnectedWalletAddress(accounts[0]);
          setIsWalletConnected(true);
        } catch (error) {
          alert('There was an issue connecting to your MetaMask wallet.');
        }
      } else {
        const response = window.confirm('Please install MetaMask to use this feature. Do you want to install it now?');
        if (response) {
          window.open('https://metamask.io/download.html', '_blank');
        }
      }
    };

    const handleUSDChange = (e) => {
      const amount = parseFloat(e.target.value);
      setUSDAmount(amount);
      setIPTAmount(amount * 2); // Assuming 1 USD is equivalent to 2 IPT for the conversion
    };

    const handleIPTChange = (e) => {
      const amount = parseFloat(e.target.value);
      setIPTAmount(amount);
      setUSDAmount(amount / 2); // Assuming 1 USD is equivalent to 2 IPT for the conversion
    };

    const logoutWallet = () => {
      setConnectedWalletAddress(null);
      setIsWalletConnected(false);
    };
    const openModal = () => {
      connectWalletMetaMask();
      setShowModal(true);
    };
  
    // Function to close the modal
    const closeModal = () => {
      setShowModal(false);
    };
    return (
      <div className="buy-page">
        {/* StartupEd logo positioned at the top left */}
        <div className='startuped-logo'>
        <a href="/index">
        <img src={toAbsoluteUrl("/media/logos/StartupEd-Logo.png")} alt="StartupEd Logo" className="startuped-logo" />
        </a>
        </div>

        <Header />


        <div className="content">
            <>
              <div className="connect-buttons"  >
                <div className="" onClick={openModal}>

                <img src="https://cdn-images-1.medium.com/v2/resize:fit:1200/1*lKHjKj1SSVQe4XjosHjrow.png" alt="NearWallet Icon" className="meta-mask-icon" /> Connect Near 
                </div>
                <div className="" onClick={openModal}>

                <img src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" alt="MetaMask Icon" className="meta-mask-icon" /> Connect MetaMask
                </div>
              </div>
            </>
            <>
            
            <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
            <Modal.Title>Buy IPT</Modal.Title>

            </Modal.Header>
            <Modal.Body>
            <WalletSection connectedWalletAddress={connectedWalletAddress} />
            <button onClick={useCallback(() => window.location.href='https://checkout.startuped.net/b/cN24hnczm5mQ1PieUV', [])} className="btn btn-info btn-sm">Buy $10</button>
  <button onClick={useCallback(() => window.location.href='https://checkout.startuped.net/b/dR615b6aY7uY9hKbIK', [])}className="btn btn-info btn-sm">Buy $18 </button>
  <button onClick={useCallback(() => window.location.href='https://checkout.startuped.net/b/14k5lrfLy9D69hKbIL', [])}className="btn btn-info btn-sm">Buy $40 </button>
  <button onClick={useCallback(() => window.location.href='https://checkout.startuped.net/b/3cs017gPC16A79C5ko', [])}className="btn btn-info btn-sm">Buy $70</button>

              <div className="buying-container">
                <div className="buying-info">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="usdAmount" className="input-label">Enter Amount in USD:</label>
                        <input
                          type="number"
                          id="usdAmount"
                          className="form-control input-field"
                          value={usdAmount}
                          onChange={handleUSDChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="iptAmount" className="input-label">Enter Amount in IPT:</label>
                        <input
                          type="number"
                          id="iptAmount"
                          className="form-control input-field"
                          value={iptAmount}
                          onChange={handleIPTChange}
                        />
                      </div>
                    </div>
                  </div>

                  

                  {/* Additional components (placeholders) */}
                  <div className="purchase-summary mt-10">
                    <h2>Order Summary</h2>
                    <p className="cart-info">IPT Amount in Cart: {iptAmount} IPT</p>
                  </div>

                  <div className="payment-button mt-4">
                    {/* Placeholder for payment button */}
                    <button className="btn btn-success">Proceed to Payment</button>
                  </div>
                </div>
              </div>
              </Modal.Body>

              <Modal.Footer>
          {/* Close the modal */}
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
            </>
        

        </div>
      
      </div>
    );
  };

  export default BuyPage;

import React, { useRef, useState } from 'react';
import MonacoEditor from 'react-monaco-editor';
import axios from 'axios';
import { languages } from './languages';
import { themes } from './themes';

const codeTemplates = {
  csharp: `using System;

class Program
{
    static void Main()
    {
        Console.WriteLine("Hello, world!");
    }
}`,
  javascript: `function greet() {
  console.log('Hello, world!');
}`,
  python: `def greet():
    print('Hello, world!')`,
  html: `<!DOCTYPE html>
<html>
<head>
  <title>Hello, World!</title>
</head>
<body>
  <h1>Hello, world!</h1>
</body>
</html>`,
  // Add more code templates for other languages
};

const MonacoEditorComponent = () => {
  const editorRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].value);
  const [selectedTheme, setSelectedTheme] = useState(themes[0].value);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const handleSaveCode = () => {
    const code = editorRef.current.getValue();
    const datetimestamp = new Date();
    const user = ''; // Set the user value, or replace '' with the desired user value

    axios
      .post("/save-code", { code, datetimestamp, user })
      .then((response) => {
        console.log('Code saved successfully:', response.data);
        setSaveSuccess(true);
        setTimeout(() => {
          setSaveSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.error('Error saving code:', error);
      });
  };

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <label htmlFor="language" className="form-label">
            Language:
          </label>
          <select
            id="language"
            className="form-select"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            {languages.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col">
          <label htmlFor="theme" className="form-label">
            Theme:
          </label>
          <select
            id="theme"
            className="form-select"
            value={selectedTheme}
            onChange={(e) => setSelectedTheme(e.target.value)}
          >
            {themes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="col-auto align-self-end">
          <button className="btn btn-primary" onClick={handleSaveCode}>
            Save
          </button>
        </div>
      </div>
      <div className="alert alert-success" role="alert" style={{ display: saveSuccess ? 'block' : 'none' }}>
        Code saved successfully!
      </div>
      <MonacoEditor
        width="auto"
        height="600"
        language={selectedLanguage}
        theme={selectedTheme}
        value={codeTemplates[selectedLanguage]}
        options={{ readOnly: false }}
        editorDidMount={handleEditorDidMount} // Pass the handler function to assign the ref
      />
    </div>
  );
};

export default MonacoEditorComponent;

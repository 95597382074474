  import React, { useState } from 'react';
  import { toAbsoluteUrl } from '../../../../_metronic/helpers';
  import '../styles/searchResultsPage.css'
  import ZeroResultBox from './ZeroResultBox';
  import { FaTwitter, FaLinkedin, FaGlobe, FaEnvelope, FaPhone } from 'react-icons/fa';
  

  const SearchResultsList = ({ searchResults, searchQuery, loading, entityTypes }) => {
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    return (
      <div id="searchresultsarea">
        {loading ? (
          <div className="metronic-loading">
            <span className="spinner-border spinner-border-sm align-center"></span>
            <span className="ml-2 align-center">Loading...</span>
          </div>
        ) : searchResults.length === 0 ? (
          <div className="no-results-message">
          <ZeroResultBox/>       
  </div>
        ) : (
          <>
            <p id="searchresultsnumber">{`Exactly ${searchResults.length} results`}</p>
            {searchResults.map((result) => (
              <div key={result.id} className="searchresult">
                <div className="entity-icon">
                  <img
                    src={result.icon_Img || toAbsoluteUrl("/media/blank.png")} // Set the default icon image path
                    alt="Entity Icon"
                  />
                </div>  

                <div className="result-details">
                <div className='tooltip-container'>
                <FaGlobe
                  className="tooltip-icon"
                  data-toggle="tooltip"
                  title="Website"
                  onClick={() => window.open(result.websiteUrl, '_blank')}
                /> 
                <FaTwitter
                className="tooltip-icon"
                data-toggle="tooltip"
                title="Twitter"
                onClick={() => window.open(result.twitterUrl, '_blank')}
              />
              <FaLinkedin
                className="tooltip-icon"
                data-toggle="tooltip"
                title="LinkedIn"
                onClick={() => window.open(result.linkedinUrl, '_blank')}
              />
              <FaEnvelope
                className="tooltip-icon disabled"
                data-toggle="tooltip"
                title="Login to view Email"
              />
              <FaPhone
                className="tooltip-icon disabled"
                data-toggle="tooltip"
                title="Login to view Phone"
              />
              </div>

                  <h2 onClick={() => window.open(`${result.domain}${result.objectUrl}`, '_blank')} className="clickable">
                    {result.name}
                  </h2>
               
                  
                  <p className={expandedDescriptions[result.id] ? '' : 'short-description'}>
                    {result.description}
                  </p>
                  <button onClick={() => setExpandedDescriptions({
                    ...expandedDescriptions,
                    [result.id]: !expandedDescriptions[result.id]
                  })}>
                    {expandedDescriptions[result.id] ? 'Read Less' : 'Read More'}
                  </button>
                  
                  <div className="pill-container">
                    <span className="pill">{result.system}</span>
                  {/* <span className="pill">{result.environment}</span>*/}
                    <span className="pill">{result.entityType}</span>
                    {/*<span className="pill">{result.scope}</span>*/}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  export default SearchResultsList;


